import React, { useMemo } from "react";
import { FaStar } from "react-icons/fa";
import dayjs from "dayjs";
import { Box } from "../../../../../shared/components/styled";
import { getDateWithFormat } from "../../../../../shared/helpers";
import { Event } from "../../../models/interfaces";
import * as S from "./ReportDetails.styles";

interface Props {
  event?: Event;
}

export const AutomaticRecords = ({ event }: Props) => {
  const score =
    event?.score_to_worker === null ? "Sin calificar" : event?.score_to_worker;

  const totalEventTime = useMemo(() => {
    if (!event?.created_at || !event?.event_end_time) return "";
    return dayjs(event?.event_end_time).diff(event?.created_at, "minutes");
  }, [event?.created_at, event?.event_end_time]);

  return (
    <Box flex flexColumn gap="1rem">
      <S.ReportItem>
        <h4>Hora de recepción de la alerta</h4>
        <span>{getDateWithFormat(event?.created_at, "h:mm A")}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Ubicación GPS</h4>
        <Box flex flexColumn alignSelf="end">
          <span>Latitud: {event?.attention_latitude}</span>
          <span>Longitud: {event?.attention_longitude}</span>
        </Box>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Tipo de alerta</h4>
        <span>{event?.service_name}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Unidad asignada</h4>
        <span>{event?.vehicle_license_plate}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Nombre del proveedor</h4>
        <span>{event?.supplier_fullname}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Tiempo estimado de arribo</h4>
        <span>{event?.estimated_time_of_arrival}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Fecha de creación del evento</h4>
        <span>{getDateWithFormat(event?.created_at)}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Hora de llegada al destino</h4>
        <span>
          {getDateWithFormat(event?.arrival_time_at_destination, "h:mm A")}
        </span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Hora de asignacion de proveedor</h4>
        <span>
          {event?.acceptance_timestamp
            ? getDateWithFormat(event?.acceptance_timestamp, "h:mm A")
            : "Sin asignar"}
        </span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Hora de llegada al destino de emergencia</h4>
        <span>
          {getDateWithFormat(
            event?.arrival_time_at_emergency_destination,
            "h:mm A"
          )}
        </span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Hora de atención</h4>
        <span>{getDateWithFormat(event?.hour_of_attention, "h:mm A")}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Hora de cierre del evento</h4>
        <span>{getDateWithFormat(event?.event_end_time, "h:mm A")}</span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Evaluación del evento</h4>
        <span>
          {score} <FaStar />
        </span>
      </S.ReportItem>
      <S.ReportItem>
        <h4>Tiempo total de atención</h4>
        <span>{totalEventTime} min</span>
      </S.ReportItem>
    </Box>
  );
};
